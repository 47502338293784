import React from 'react';

const About = () => {
    return (
        <div className="section" id="about">
            <h2>О проекте</h2>
            <p>
                Мы — Hornet On Ton, проект, который нацелен на бесконечное развитие путём поощрения постоянных холдеров.
            </p>
            <p>
                Холдеры HOT, у которых в наличии есть клетки с особым статусом, будут получать airdrop в TON. 
                Подробнее о механике получения клеток и смены их статуса на особый можно узнать в 
                <a href="https://t.me/hornet_ton_bot" target="_blank" rel="noopener noreferrer" style={{ color: '#0088cc', textDecoration: 'none' }}> БОТЕ.</a>
            </p>
        </div>
    );
};

export default About;