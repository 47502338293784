import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import Header from './components/Header';
import DependencyCalculator from './components/DependencyCalculator';
import About from './components/About';
import Roadmap from './components/Roadmap';
import './App.css';
import Footer from './components/Footer';

function App() {
    return (
        <div className="App">
            {/* AppBar */}
            <nav className="appbar">
                <div className="appbar-logo">
                    <img src='./logo.jpg' alt="MIF Logo" className="logo-style" />
                </div>
                <div className="appbar-links">
                    <Link
                        to="header"
                        smooth={true}
                        duration={500}
                        offset={-window.innerHeight / 1.5}
                        className="appbar-link"
                    >
                        Главная
                    </Link>
                    <Link
                        to="about"
                        smooth={true}
                        duration={500}
                        offset={-window.innerHeight / 3}
                        className="appbar-link"
                    >
                        О проекте
                    </Link>
                    <Link
                        to="roadmap"
                        smooth={true}
                        duration={500}
                        offset={-window.innerHeight / 3}
                        className="appbar-link"
                    >
                        Роадмапа
                    </Link>
                </div>
            </nav>

            {/* Секции */}
            <Header />
            <About />
            <Roadmap />
            <Footer />
        </div>
    );
}

export default App;