import React from 'react';
import { FaCheckCircle, FaTools, FaCalendarAlt } from 'react-icons/fa';
import './Roadmap.css';

const Roadmap = () => {
    return (
        <div className="section" id="roadmap">
            <h2>Роадмапа</h2>
            <div className="roadmap-container">
                {/* Выполненные задачи */}
                <div className="roadmap-column">
                    <h3><FaCheckCircle /> Выполнено</h3>
                    <div className="roadmap-card">
                        <h4>Лаунчинг Токена</h4>
                        <p>Запуск токена на блокчейне TON.</p>
                    </div>
                    <div className="roadmap-card">
                        <h4>Запуск Игры</h4>
                        <p>Запуск интерактивной игры в Telegram MiniApps.</p>
                    </div>
                    <div className="roadmap-card">
                        <h4>Запуск Информационного Сайта</h4>
                        <p>Ты сейчас на нём читаешь это.</p>
                    </div>
                    <div className="roadmap-card">
                        <h4>Первая Раздача Airdrop статусов</h4>
                        <p>Первые клетки со статусом airdrop уже на счету у своих владельцев.</p>
                    </div>
                </div>

                {/* Предстоящие задачи */}
                <div className="roadmap-column">
                    <h3><FaTools /> В разработке</h3>
                    <div className="roadmap-card">
                        <h4>Внедрение Ton Connection</h4>
                        <p>Подключайте кошелёк и получайте больше контроля над своими HOT-активами.</p>
                    </div>
                    <div className="roadmap-card">
                        <h4>Внутренний P2P Market Клеток</h4>
                        <p>Покупай или продавай свои особые клетки прямо в приложении.</p>
                    </div>
                    <div className="roadmap-card">
                        <h4>Появлени «Битых» Клеток</h4>
                        <p>Механика сжигания. Готовьтесь избавляться от битых клеток с пользой для себя и комьюнити!</p>
                    </div>
                    <div className="roadmap-card">
                        <h4>Первый Airdrop TON</h4>
                        <p>Первые холдеры получили airdrop.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Roadmap;